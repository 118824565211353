import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Container,
  AvatarContainer,
  Avatar,
  AvatarEditButton,
  UsernameContainer,
  Username,
  UsernameEditButton,
  ScoreContainer,
  ScoreImg,
  Score,
  RankingContainer,
  RankingImg,
  Ranking,
  PrivacyPolicyLink,
  ScoreTitle,
  RankingTitle,
} from './ProfileScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import {
  getUser,
  logout,
  updateAvatar,
  updateUser,
} from '../../store/actions/auth';
import FooterBar from '../../components/FooterBar/FooterBar';
import LogoutIcon from '../../assets/images/logout.svg';
import FAQsIcon from '../../assets/images/info.svg';
import DefaultAvatar from '../../assets/images/avatar_big.svg';
import EditIcon from '../../assets/images/edit.svg';
import ScoreIcon from '../../assets/images/score.svg';
import RankingIcon from '../../assets/images/ranking.svg';

const ProfileScreen = (props) => {
  const { history, routes } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.user);

  const [avatar, setAvatar] = useState({ preview: '', raw: '' });

  const avatarFileRef = useRef(null);

  const openFAQs = useCallback(() => {
    history.push(routes.FAQS.path);
  }, [history, routes]);

  const openRanking = useCallback(() => {
    history.push(routes.RANKING.path);
  }, [history, routes]);

  const logoutHandler = useCallback(() => {
    const canLogout = confirm(t('logout_confirm_message'));

    if (canLogout) {
      dispatch(logout());
    }
  }, [dispatch, t]);

  const topLeftButton = useMemo(
    () => ({
      action: logoutHandler,
      icon: LogoutIcon,
      description: 'logout',
    }),
    [logoutHandler],
  );

  const topRightButton = useMemo(
    () => ({
      action: openFAQs,
      icon: FAQsIcon,
      description: 'faqs',
    }),
    [openFAQs],
  );

  const editUsernameHandler = () => {
    const newUsername = prompt(t('edit_username'), user?.username);

    if (newUsername && newUsername !== '') {
      dispatch(updateUser(newUsername, t))
        .then((message) => {
          alert(message);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleChange = (e) => {
    if (e.target.files.length) {
      const raw = e.target.files[0];
      setAvatar({
        preview: URL.createObjectURL(e.target.files[0]),
        raw,
      });

      dispatch(updateAvatar(raw, t))
        .then((message) => {
          alert(message);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const avatarPickerHandler = () => {
    avatarFileRef.current?.click();
  };

  return (
    <Page>
      <TopBar
        title={t('profile')}
        leftButton={topLeftButton}
        rightButton={topRightButton}
      />
      <Container>
        <AvatarContainer onClick={avatarPickerHandler}>
          <Avatar
            src={
              avatar.preview || user?.avatar
                ? process.env.REACT_APP_API_BASE_URL?.replace(
                    'api',
                    user.avatar,
                  )
                : DefaultAvatar
            }
          />
          <AvatarEditButton src={EditIcon} />
          <input
            ref={avatarFileRef}
            type="file"
            accept="image/png, image/jpeg"
            id="upload-avatar-button"
            style={{ display: 'none' }}
            onChange={handleChange}
          />
        </AvatarContainer>
        <UsernameContainer onClick={editUsernameHandler}>
          <Username>{user?.username}</Username>
          <UsernameEditButton src={EditIcon} />
        </UsernameContainer>
        <ScoreContainer>
          <ScoreImg src={ScoreIcon} />
          <ScoreTitle>{t('score')}</ScoreTitle>
          <Score>{`${user?.points ?? 0} pts`}</Score>
        </ScoreContainer>
        <RankingContainer onClick={openRanking}>
          <RankingImg src={RankingIcon} />
          <RankingTitle>{t('ranking')}</RankingTitle>
          <Ranking>{user?.ranking_order ?? '-'}</Ranking>
        </RankingContainer>

        <PrivacyPolicyLink
          href={routes.POLICY.path}
          target="__blank"
          rel="noreferrer noopener"
        >
          {t('privacy_policy')}
        </PrivacyPolicyLink>
      </Container>

      <FooterBar routes={routes} />
    </Page>
  );
};

export default withRouter(ProfileScreen);
