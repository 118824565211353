import styled from 'styled-components';

export const CardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  align-items: center;
  background-color: ${(props) => props.color};
  display: flex;
  padding: 0px 10px;
  position: absolute;
`;

export const Subtitle = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 23px;
  color: white;
  text-transform: uppercase;
`;
