import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import TopBar from '../../components/TopBar/TopBar';
import CustomAccordion from '../../components/CustomAccordion/CustomAccordion';
import CustomButton from '../../components/CustomButton/CustomButton';
import {
  Page,
  Container,
  DetailImage,
  Subtitle,
  BottomContainer,
} from './ProfileAreaInfoScreen.styles';
import { colors } from '../../constants/colors';
import { getAreas } from '../../store/actions/areas';
import { getProfiles } from '../../store/actions/profiles';
import { ITEM_TYPES } from '../../constants';

const ProfileAreaInfoScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areas = useSelector((state) => state.areas.items);
  const profiles = useSelector((state) => state.profiles.items);

  const itemInfo = useMemo(() => {
    if (history.location.pathname.includes(routes.PROFILES.path)) {
      if (profiles) {
        return profiles.find(
          (item) => item.id === history.location.state?.itemId,
        );
      }
      dispatch(getProfiles());
      return null;
    }
    if (history.location.pathname.includes(routes.AREAS.path)) {
      if (areas) {
        return areas.find((item) => item.id === history.location.state?.itemId);
      }
      dispatch(getAreas());
      return null;
    }

    return null;
  }, [history, profiles, areas, routes, dispatch]);

  const titles = useMemo(() => itemInfo?.items?.map((item) => item.title), [
    itemInfo,
  ]);

  const quizClickHandler = () => {
    const type = history.location.pathname.includes(routes.AREAS.path)
      ? ITEM_TYPES.AREAS
      : ITEM_TYPES.PROFILES;

    history.push({
      pathname: routes.QUIZ.path
        .replace(':type', type)
        .replace(':id', itemInfo.id),
      state: {
        profileId: type === ITEM_TYPES.PROFILES ? itemInfo.id : null,
        areaId: type === ITEM_TYPES.AREAS ? itemInfo.id : null,
        itemInfo,
        type,
      },
    });
  };

  const videoClickHandler = () => {
    window.open(itemInfo.yt_video_url, '_blank').focus();
  };

  return (
    <Page>
      {itemInfo && (
        <>
          <TopBar
            hasBackButton
            title={itemInfo?.name}
            backgroundColor={itemInfo?.color}
          />
          <Container>
            <DetailImage alt="detail" src={itemInfo?.picture_detail} />
            <Subtitle>{itemInfo?.subtitle}</Subtitle>
            <CustomAccordion headerColor={itemInfo?.color} titles={titles}>
              {itemInfo?.items?.map((item) => (
                <span
                  key={item.id}
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
              ))}
            </CustomAccordion>
          </Container>
          {itemInfo?.has_quiz && (
            <BottomContainer>
              <CustomButton
                backgroundColor={colors.blueMagenta}
                color="white"
                text={t('quiz').toLocaleUpperCase()}
                onClick={quizClickHandler}
              />
            </BottomContainer>
          )}
          {itemInfo?.yt_video_url && (
            <BottomContainer>
              <CustomButton
                backgroundColor={colors.darkOcean}
                color="white"
                text={t('video').toLocaleUpperCase()}
                onClick={videoClickHandler}
              />
            </BottomContainer>
          )}
        </>
      )}
    </Page>
  );
};

export default withRouter(ProfileAreaInfoScreen);
