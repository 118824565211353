import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  flex: 1;
`;

export const SubHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  height: 60px;
`;

export const SubHeaderTitle = styled.span`
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'ProximaNova-Bold';
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  width: 100%;
  align-items: center;
`;

export const DetailImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 25vh;
`;

export const Feedback = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 29px;
  color: ${colors.blueMagenta};
  text-transform: uppercase;
  margin-top: 18px;
`;

export const QuestionsCounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  font-size: 20px;
  font-family: 'ProximaNova-SemiBold';
  color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
`;

export const QuestionsCounter = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 20px;
  color: ${colors.blueMagenta};
  margin-right: 10px;
`;

export const Statistics = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const StatisticsItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-family: 'ProximaNova-SemiBold';
  align-items: center;
  color: rgba(0, 0, 0, 0.5);

  &:first-child {
    margin-right: 50px;
  }
`;

export const StatisticIcon = styled.img`
  width: 19px;
  height: 14px;
  margin-right: 7px;
`;

export const StatisticsInfo = styled.span`
  font-family: 'ProximaNova-Bold';
  margin-right: 10px;
  color: #000;
`;

export const Points = styled.span`
  margin-top: 26px;
  font-size: 25px;
  color: #000;
  font-family: 'ProximaNova-Bold';
`;

export const PointsSymbol = styled.span`
  color: rgba(0, 0, 0, 0.5);
  margin-right: 7px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
