import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Container,
  SubHeader,
  SubHeaderTitle,
  DetailImage,
  Feedback,
  QuestionsCounterContainer,
  QuestionsCounter,
  BottomContainer,
  Statistics,
  StatisticsItem,
  StatisticIcon,
  StatisticsInfo,
  Points,
  PointsSymbol,
} from './QuizResultsScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import CustomButton from '../../components/CustomButton/CustomButton';
import { getQuiz, resetQuizState } from '../../store/actions/quiz';
import { colors } from '../../constants/colors';
import CorrectIcon from '../../assets/images/correct.svg';
import WrongIcon from '../../assets/images/wrong.svg';

const QuizResultsScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const results = useSelector((state) => state.quiz.quizResults);

  const [itemInfo, setItemInfo] = useState(null);

  useEffect(() => {
    const historyState = history.location.state;

    setItemInfo(historyState?.itemInfo);

    if (!results) {
      dispatch(
        getQuiz({
          profileId: historyState.profileId,
          areaId: historyState.areaId,
        }),
      );
    }
  }, [dispatch, results, history]);

  const goHome = () => {
    dispatch(resetQuizState());
    history.replace(routes.HOME.path);
  };

  return (
    <Page>
      <TopBar title={t('quiz_results')} />
      {results && itemInfo && (
        <>
          <SubHeader backgroundColor={itemInfo.color}>
            <SubHeaderTitle>{itemInfo.name}</SubHeaderTitle>
          </SubHeader>
          <Container>
            <DetailImage alt="detail" src={itemInfo.picture_detail} />
            <Feedback>{`${t('thank_you')}!`}</Feedback>
            <QuestionsCounterContainer>
              <QuestionsCounter>{`${
                results.incorrect_answers + results.correct_answers
              } / ${
                results.incorrect_answers + results.correct_answers
              }`}</QuestionsCounter>
              {t('questions')}
            </QuestionsCounterContainer>
            <Statistics>
              <StatisticsItem>
                <StatisticIcon src={CorrectIcon} />
                <span>
                  <StatisticsInfo>{results.correct_answers}</StatisticsInfo>
                  {t('corrects')}
                </span>
              </StatisticsItem>
              <StatisticsItem>
                <StatisticIcon src={WrongIcon} />
                <span>
                  <StatisticsInfo>{results.incorrect_answers}</StatisticsInfo>
                  {t('incorrects')}
                </span>
              </StatisticsItem>
            </Statistics>
            <Points>
              <PointsSymbol>+</PointsSymbol>
              {`${results.points} pts`}
            </Points>
          </Container>
          <BottomContainer>
            <CustomButton
              backgroundColor={colors.blueMagenta}
              color="white"
              text={t('finish').toLocaleUpperCase()}
              onClick={goHome}
            />
          </BottomContainer>
        </>
      )}
    </Page>
  );
};

export default QuizResultsScreen;
