import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  flex: 1;
`;

export const SubHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  height: 60px;
`;

export const SubHeaderTitle = styled.span`
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'ProximaNova-Bold';
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  width: 100%;
  padding: 18px 30px 23px 30px;
  align-items: center;
`;

export const QuestionsCounter = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 20px;
  color: ${colors.blueMagenta};
`;

export const Question = styled.span`
  text-align: center;
  font-family: 'ProximaNova-Regular';
  color: #000;
  font-size: 19px;
  margin-top: 15px;
`;

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 23px;
`;
