import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { FooterBarContainter, FooterButton, Icon } from './FooterBar.styles';
import { colors } from '../../constants/colors';
import ChecklistIcon from '../../assets/images/checklist.svg';
import ChecklistActiveIcon from '../../assets/images/checklist_active.svg';
import HomeIcon from '../../assets/images/home.svg';
import HomeActiveIcon from '../../assets/images/home_active.svg';
import ProfileIcon from '../../assets/images/profile.svg';
import ProfileActiveIcon from '../../assets/images/profile_active.svg';

const FooterBar = (props) => {
  const { history, routes } = props;

  const isHomeActive = useMemo(
    () =>
      history.location.pathname === routes.HOME.path ||
      history.location.pathname.includes(routes.AREAS.path) ||
      history.location.pathname.includes(routes.PROFILES.path),
    [history, routes],
  );

  const isChecklistActive = useMemo(
    () => history.location.pathname === routes.CHECKLIST.path,
    [history, routes],
  );

  const isProfileActive = useMemo(
    () =>
      history.location.pathname === routes.PROFILE.path ||
      history.location.pathname === routes.RANKING.path ||
      history.location.pathname === routes.FAQS.path,
    [history, routes],
  );

  const homeClickHandler = () => {
    history.push(routes.HOME.path);
  };

  const checklistClickHandler = () => {
    history.push(routes.CHECKLIST.path);
  };

  const profileClickHandler = () => {
    history.push(routes.PROFILE.path);
  };

  return (
    <FooterBarContainter {...colors}>
      <FooterButton type="button" onClick={homeClickHandler}>
        <Icon alt="home" src={isHomeActive ? HomeActiveIcon : HomeIcon} />
      </FooterButton>
      <FooterButton type="button" onClick={checklistClickHandler}>
        <Icon
          alt="checklist"
          src={isChecklistActive ? ChecklistActiveIcon : ChecklistIcon}
        />
      </FooterButton>
      <FooterButton type="button" onClick={profileClickHandler}>
        <Icon
          alt="profile"
          src={isProfileActive ? ProfileActiveIcon : ProfileIcon}
        />
      </FooterButton>
    </FooterBarContainter>
  );
};

export default withRouter(FooterBar);
