import styled from 'styled-components';
import { Card, Accordion } from 'react-bootstrap';
import { colors } from '../../constants/colors';

export const AccordionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const AccordionHeaderTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const AccordionHeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 7px;
`;

export const AccordionHeaderFilledIcon = styled.img`
  width: 17px;
  height: 15px;
  margin-top: -3px;
  margin-right: 10px;
`;

export const AccordionHeaderFilledCounter = styled.span`
  color: #fff;
  font-size: 17px;
  font-family: 'ProximaNova-Regular';
  margin-right: 16px;
`;

export const AccordionIcon = styled.i`
  color: #fff;
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
  padding: 0px 22px;

  .card {
    margin-bottom: 20px;
    border: 0;
    border-radius: 13px !important;
  }
`;

export const CardHeader = styled(Card.Header)`
  border: 0;
  min-height: 41px;
  background-color: ${colors.blueMagenta};
  border: 0;
  padding-bottom: 10px;
  font-family: 'ProximaNova-SemiBold';
  font-size: 15px;
  color: #fff;
`;

export const CardBody = styled(Card.Body)`
  font-size: 14px;
  padding-top: 0px;
  color: #000;
  border: 1px solid ${colors.blueMagenta};
  border-radius: 0 0 13px 13px;
`;
