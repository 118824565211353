import styled from 'styled-components';

export const FooterBarContainter = styled.div`
  align-items: center;
  background-color: ${(props) => props.darkFooterBar};
  display: flex;
  justify-content: space-between;
  min-height: 10vh;
  padding: 0px 34px;
  width: 100%;
  height: 60px;
`;

export const FooterButton = styled.button`
  background-color: transparent;
  border: 0;
`;

export const Icon = styled.img`
  width: 23px;
  height: 23px;
  object-fit: scale-down;
`;
