import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOnboardingItems } from '../../store/actions/onboarding';
import TopBar from '../../components/TopBar/TopBar';
import {
  Page,
  Container,
  LogoImage,
  BottomContainer,
} from '../WelcomeScreen/WelcomeScreen.styles';
import Logo from '../../assets/images/logo.png';
import LogoTDP from '../../assets/images/logo-tdp.png';
import LogoAcPT from '../../assets/images/logo-accessible-portugal.png';

const PolicyScreen = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOnboardingItems());
  }, [dispatch]);

  return (
    <Page>
      <TopBar hasBackButton />
      <Container>
        <div
          style={{ padding: '20px', color: '#fff' }}
          dangerouslySetInnerHTML={{ __html: t('policy_content') }}
        />
        <BottomContainer>
          <div>
            <LogoImage
              className="logo_img"
              alt="logo-tdp"
              src={LogoTDP}
              style={{
                filter: 'brightness(0) invert(1)',
                margin: '10px',
                maxWidth: '100px',
              }}
            />
            <LogoImage
              className="logo_img"
              alt="logo-acpt"
              src={LogoAcPT}
              style={{
                filter: 'brightness(0) invert(1)',
                margin: '10px',
                maxWidth: '100px',
              }}
            />
          </div>
        </BottomContainer>
      </Container>
    </Page>
  );
};

export default withRouter(PolicyScreen);
