import styled, { css } from 'styled-components';
import { colors } from '../../constants/colors';

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  min-height: 50px;
  border-bottom: 2px solid ${colors.blueMagenta};
  padding-left: 13px;
  padding-right: 16px;

  ${(props) =>
    props.isLoggedUser &&
    css`
      background-color: ${colors.blueMagenta};
    `}
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Position = styled.div`
  align-items: center;
  display: flex;
  color: #fff;
  font-family: 'ProximaNova-Bold';
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  justify-content: center;
  margin-right: 20px;

  ${(props) =>
    props.position === 1 &&
    css`
      background-color: ${colors.gold};
      color: #fff;
    `}

  ${(props) =>
    props.position === 2 &&
    css`
      background-color: ${colors.silver};
      color: #fff;
    `}

    ${(props) =>
    props.position === 3 &&
    css`
      background-color: ${colors.bronze};
      color: #fff;
    `}

    ${(props) =>
    props.position > 3 &&
    css`
      background-color: transparent;
      color: #000;
      font-family: 'ProximaNova-Regular';
    `}

    ${(props) =>
    props.isLoggedUser &&
    css`
      color: #fff;
      font-family: 'ProximaNova-Regular';
      background-color: transparent;
    `}
`;

export const Avatar = styled.img`
  margin-right: 20px;
  color: white;
  width: 26px;
  height: 26px;
  object-fit: cover;
  border-radius: 13px;
`;

export const Username = styled.span`
  font-family: 'ProximaNova-Regular';
  font-size: 12px;
  margin-right: 20px;
  color: ${(props) => (props.isLoggedUser ? '#fff' : '#000')};
`;

export const Points = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 12px;
  justify-self: flex-end;
  color: ${(props) => (props.isLoggedUser ? '#fff' : '#000')};
`;
