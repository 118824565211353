import React from 'react';
import { CardContainer, Subtitle, TextContainer } from './ListCard.styles';
import { colors } from '../../constants/colors';

const ListCard = (props) => {
  const { onClick, item } = props;

  return (
    <CardContainer
      backgroundImage={item.picture_index}
      onClick={() => onClick(item)}
      {...colors}
    >
      <TextContainer color={item.color}>
        <Subtitle>{item.name}</Subtitle>
      </TextContainer>
    </CardContainer>
  );
};

export default ListCard;
