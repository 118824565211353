import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Container } from './KnowMoreScreen.styles';
import TopBar from '../../components/TopBar/TopBar';

const KnowMoreScreen = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <TopBar hasBackButton />
      <Container>
        <div dangerouslySetInnerHTML={{ __html: t('know_more_text') }} />
      </Container>
    </Page>
  );
};

export default KnowMoreScreen;
