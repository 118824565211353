// GENERAL STATUSES
export const SUCCESS = 'success';
export const ERROR = 'error';

// HTTP STATUS
export const HTTP_STATUS = {
  UNHAUTORIZED: 401,
  FORBIDDEN: 403,
  SUCCESS: 200,
  SUCCESS_CREATED: 201,
  SUCCESS_ACCEPTED: 202,
  ERROR: 500,
  NOT_FOUND: 404,
  CONFLICT: 409,
};

export const ITEM_TYPES = {
  PROFILES: 'profiles',
  AREAS: 'areas',
};
