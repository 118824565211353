export const GET_ONBOARDING_ITEMS_START = 'GET_ONBOARDING_ITEMS_START';
export const GET_ONBOARDING_ITEMS_SUCCESS = 'GET_ONBOARDING_ITEMS_SUCCESS';
export const GET_ONBOARDING_ITEMS_ERROR = 'GET_ONBOARDING_ITEMS_ERROR';

export const GET_AREAS_START = 'GET_AREAS_START';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';
export const GET_AREAS_ERROR = 'GET_AREAS_ERROR';

export const GET_PROFILES_START = 'GET_PROFILES_START';
export const GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS';
export const GET_PROFILES_ERROR = 'GET_PROFILES_ERROR';

export const GET_FAQS_START = 'GET_FAQS_START';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_ERROR = 'GET_FAQS_ERROR';

export const GET_RANKING_START = 'GET_RANKING_START';
export const GET_RANKING_SUCCESS = 'GET_RANKING_SUCCESS';
export const GET_RANKING_ERROR = 'GET_RANKING_ERROR';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGOUT = 'LOGOUT';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const GET_QUIZ_START = 'GET_QUIZ_START';
export const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
export const GET_QUIZ_ERROR = 'GET_QUIZ_ERROR';

export const REGISTER_LAST_ANSWER_SUCCESS = 'REGISTER_LAST_ANSWER_SUCCESS';

export const RESET_QUIZ_STATE = 'RESET_QUIZ_STATE';

export const GET_CHECKLIST_START = 'GET_CHECKLIST_START';
export const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS';
export const GET_CHECKLIST_ERROR = 'GET_CHECKLIST_ERROR';
