import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Container, SpinnerWrapper } from './RankingScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import { getRanking } from '../../store/actions/ranking';
import FooterBar from '../../components/FooterBar/FooterBar';
import RankingItem from '../../components/RankingItem/RankingItem';

const RankingScreen = (props) => {
  const { routes } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const generalRanking = useSelector((state) => state.ranking.generalRanking);
  const userRanking = useSelector((state) => state.ranking.userRanking);
  const loading = useSelector((state) => state.ranking.loading);

  useEffect(() => {
    dispatch(getRanking()).catch((error) => {
      alert(error);
    });
  }, [dispatch]);

  return (
    <Page>
      <TopBar hasBackButton title={t('ranking')} />
      {loading ? (
        <SpinnerWrapper>
          <div className="spinner-border" role="status" />
        </SpinnerWrapper>
      ) : (
        generalRanking && (
          <>
            <Container>
              {generalRanking.map((item) => (
                <RankingItem
                  key={`key_${item.id}_${item.ranking_order}`}
                  item={item}
                />
              ))}
            </Container>
            {userRanking && <RankingItem isLoggedUser item={userRanking} />}
          </>
        )
      )}

      <FooterBar routes={routes} />
    </Page>
  );
};

export default RankingScreen;
