import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import TopBar from '../../components/TopBar/TopBar';
import ListCard from '../../components/ListCard/ListCard';
import FooterBar from '../../components/FooterBar/FooterBar';
import { Page, Container } from './ProfilesAreasScreen.styles';
import { getAreas } from '../../store/actions/areas';
import { getProfiles } from '../../store/actions/profiles';

const ProfilesAreasScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areas = useSelector((state) => state.areas.items);
  const profiles = useSelector((state) => state.profiles.items);

  const loadingAreas = useSelector((state) => state.areas.loading);
  const loadingProfiles = useSelector((state) => state.profiles.loading);

  const title = useMemo(() => {
    if (history.location.pathname === routes.PROFILES.path) {
      return t('select_profile');
    }
    if (history.location.pathname === routes.AREAS.path) {
      return t('select_area');
    }

    return null;
  }, [history, routes, t]);

  useEffect(() => {
    if (history.location.pathname === routes.PROFILES.path) {
      dispatch(getProfiles());
    }
    if (history.location.pathname === routes.AREAS.path) {
      dispatch(getAreas());
    }
  }, [dispatch, history, routes]);

  const itemClickHandler = (item) => {
    if (history.location.pathname === routes.PROFILES.path) {
      history.push({
        pathname: routes.PROFILE_INFO.path.replace(':id', item.id),
        state: {
          itemId: item.id,
        },
      });
    }
    if (history.location.pathname === routes.AREAS.path) {
      history.push({
        pathname: routes.AREA_INFO.path.replace(':id', item.id),
        state: {
          itemId: item.id,
        },
      });
    }
  };

  return (
    <Page>
      <TopBar hasBackButton subtitle={title} />
      <Container>
        {loadingAreas || loadingProfiles ? (
          <div className="spinner-border" role="status" />
        ) : (
          <>
            {history.location.pathname === routes.PROFILES.path &&
              profiles?.map((item) => (
                <ListCard
                  key={item.id}
                  onClick={itemClickHandler}
                  item={item}
                />
              ))}
            {history.location.pathname === routes.AREAS.path &&
              areas?.map((item) => (
                <ListCard
                  key={item.id}
                  onClick={itemClickHandler}
                  item={item}
                />
              ))}
          </>
        )}
      </Container>
      <FooterBar routes={routes} />
    </Page>
  );
};

export default withRouter(ProfilesAreasScreen);
