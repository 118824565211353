import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkbox from 'react-custom-checkbox';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomInput from '../../components/CustomInput/CustomInput';
import { colors } from '../../constants/colors';
import {
  Page,
  LogoImage,
  Line,
  CheckboxWrapper,
  PrivacyPolicyLabel,
} from './RegisterScreen.styles';
import Logo from '../../assets/images/logo.png';
import { signup } from '../../store/actions/auth';

const RegisterScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);
  const user = useSelector((state) => state.auth.user);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [privacyPolicyConditionsChecked, setPrivacyPolicyChecked] = useState(
    false,
  );

  const registerClickHandler = () => {
    if (!privacyPolicyConditionsChecked) {
      alert(t('privacy_policy_error'));
      return;
    }
    dispatch(signup(name, email, username, password, t)).catch((error) => {
      alert(error);
    });
  };

  useEffect(() => {
    if (user) {
      history.replace(routes.HOME.path);
    }
  }, [user]);

  return (
    <Page>
      <LogoImage className="logo_img" alt="logo" src={Logo} />
      <CustomInput
        placeholder={t('name')}
        borderColor={colors.blueMagenta}
        marginTop="26px"
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <CustomInput
        placeholder={t('username')}
        borderColor={colors.blueMagenta}
        marginTop="13px"
        onChange={(e) => setUsername(e.target.value)}
        value={username}
      />
      <CustomInput
        placeholder={t('email')}
        borderColor={colors.blueMagenta}
        marginTop="13px"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <CustomInput
        placeholder={t('password')}
        borderColor={colors.blueMagenta}
        marginTop="13px"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />

      <CheckboxWrapper
        onClick={() => setPrivacyPolicyChecked((prevState) => !prevState)}
      >
        <Checkbox
          onChange={() => setPrivacyPolicyChecked((prevState) => !prevState)}
          borderColor={colors.blueMagenta}
          borderWidth={1}
          containerStyle={{
            backgroundColor: colors.blueMagenta,
            borderRadius: 5,
          }}
          icon={
            <div
              style={{
                backgroundColor: '#fff',
                borderRadius: 5,
                padding: 5,
              }}
            />
          }
        />
        <PrivacyPolicyLabel
          dangerouslySetInnerHTML={{
            __html: t('privacy_policy_message', {
              link: routes.POLICY.path,
              privacy_policy: t('privacy_policy'),
            }),
          }}
        />
      </CheckboxWrapper>

      <CustomButton
        backgroundColor={colors.greenCyan}
        color="white"
        text={t('register')}
        marginTop="35px"
        onClick={registerClickHandler}
        disabled={loading}
      />

      <Line />

      <CustomButton
        backgroundColor={colors.gray}
        color="white"
        text={t('login')}
        marginTop="22px"
        onClick={() => history.push(routes.LOGIN.path)}
        disabled={loading}
      />
    </Page>
  );
};

export default withRouter(RegisterScreen);
