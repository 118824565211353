import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import WelcomeScreen from '../screens/WelcomeScreen/WelcomeScreen';
import PolicyScreen from '../screens/PolicyScreen/PolicyScreen';
import KnowMoreScreen from '../screens/KnowMoreScreen/KnowMoreScreen';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import RecoverPasswordScreen from '../screens/RecoverPasswordScreen/RecoverPasswordScreen';
import ProfilesAreasScreen from '../screens/ProfilesAreasScreen/ProfilesAreasScreen';
import ProfileAreaInfoScreen from '../screens/ProfileAreaInfoScreen/ProfileAreaInfoScreen';
import RegisterScreen from '../screens/RegisterScreen/RegisterScreen';
import HomeScreen from '../screens/HomeScreen/HomeScreen';
import QuizScreen from '../screens/QuizScreen/QuizScreen';
import QuizResultsScreen from '../screens/QuizResultsScreen/QuizResultsScreen';
import ChecklistScreen from '../screens/ChecklistScreen/ChecklistScreen';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen';
import RankingScreen from '../screens/RankingScreen/RankingScreen';
import FAQsScreen from '../screens/FAQsScreen/FAQsScreen';
import TrackedRoute from './TrackedRoute';
import ChangePasswordScreen from '../screens/ChangePasswordScreen/ChangePasswordScreen';

const routes = {
  WELCOME: { path: '/', component: WelcomeScreen, private: false },
  POLICY: {
    path: '/policy',
    component: PolicyScreen,
    private: false,
  },
  KNOW_MORE: {
    path: '/know-more',
    component: KnowMoreScreen,
    private: false,
  },
  LOGIN: { path: '/login', component: LoginScreen, private: false },
  RECOVER_PASSWORD: {
    path: '/recover-password',
    component: RecoverPasswordScreen,
    private: false,
  },
  REGISTER: {
    path: '/register',
    component: RegisterScreen,
    private: false,
  },
  HOME: { path: '/home', component: HomeScreen, private: false },
  AREAS: {
    path: '/areas',
    component: ProfilesAreasScreen,
    private: false,
  },
  AREA_INFO: {
    path: '/areas/:id',
    component: ProfileAreaInfoScreen,
    private: false,
  },
  PROFILES: {
    path: '/profiles',
    component: ProfilesAreasScreen,
    private: false,
  },
  PROFILE_INFO: {
    path: '/profiles/:id',
    component: ProfileAreaInfoScreen,
    private: false,
  },
  QUIZ: {
    path: '/:type/:id/quiz',
    component: QuizScreen,
    private: true,
  },
  QUIZ_RESULTS: {
    path: '/:type/:id/quiz/results',
    component: QuizResultsScreen,
    private: true,
  },
  CHECKLIST: {
    path: '/checklist',
    component: ChecklistScreen,
    private: true,
  },
  PROFILE: {
    path: '/profile',
    component: ProfileScreen,
    private: true,
  },
  RANKING: {
    path: '/ranking',
    component: RankingScreen,
    private: true,
  },
  FAQS: {
    path: '/faqs',
    component: FAQsScreen,
    private: false,
  },
  CHANGE_PASSWORD: {
    path: '/changepassword',
    component: ChangePasswordScreen,
    private: false,
  },
};

const GlobalRoutes = () => (
  <BrowserRouter>
    <Switch>
      {Object.values(routes).map((route) => {
        if (route.private) {
          return (
            <PrivateRoute
              key={route.path}
              exact
              path={route.path}
              routes={routes}
              component={route.component}
            />
          );
        }
        return (
          <TrackedRoute
            key={route.path}
            exact
            path={route.path}
            render={() => <route.component routes={routes} />}
          />
        );
      })}
    </Switch>
  </BrowserRouter>
);

export default GlobalRoutes;
