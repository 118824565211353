import React, { useMemo } from 'react';
import {
  ItemContainer,
  LeftContainer,
  Position,
  Avatar,
  Username,
  Points,
} from './RankingItem.styles';
import LoggedUserDefaultAvatar from '../../assets/images/avatar_small_white.svg';
import UserDefaultAvatar from '../../assets/images/avatar_small.svg';

const RankingItem = (props) => {
  const { item, isLoggedUser } = props;

  const avatar = useMemo(() => {
    if (item.avatar) {
      return process.env.REACT_APP_API_BASE_URL?.replace('api', item.avatar);
    }

    if (isLoggedUser) {
      return LoggedUserDefaultAvatar;
    }
    return UserDefaultAvatar;
  }, [item, isLoggedUser]);

  return (
    <ItemContainer isLoggedUser={isLoggedUser} {...props}>
      <LeftContainer>
        <Position isLoggedUser={isLoggedUser} position={item.ranking_order}>
          {item.ranking_order ?? '-'}
        </Position>
        <Avatar isLoggedUser={isLoggedUser} src={avatar} />
        <Username isLoggedUser={isLoggedUser}>
          {item.username ?? item.name}
        </Username>
      </LeftContainer>
      <Points isLoggedUser={isLoggedUser}>{`${item.points ?? '0'} pts`}</Points>
    </ItemContainer>
  );
};

export default RankingItem;
