import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';

import onboardingReducer from './reducers/onboarding';
import areasReducer from './reducers/areas';
import profilesReducer from './reducers/profiles';
import faqsReducer from './reducers/faqs';
import rankingReducer from './reducers/ranking';
import authReducer from './reducers/auth';
import quizReducer from './reducers/quiz';
import checklistReducer from './reducers/checklist';

export const rootReducer = combineReducers({
  onboarding: onboardingReducer,
  areas: areasReducer,
  profiles: profilesReducer,
  faqs: faqsReducer,
  ranking: rankingReducer,
  auth: authReducer,
  quiz: quizReducer,
  checklist: checklistReducer,
});

export default createStore(rootReducer, applyMiddleware(ReduxThunk));
