import styled from 'styled-components';
import BackgroundImage from '../../assets/images/bg.png';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: url(${BackgroundImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const LogoImage = styled.img`
  width: 90%;
  max-width: 310px;
`;

export const Subtitle = styled.label`
  font-family: 'ProximaNova-Bold';
  font-size: 15px;
  color: white;
  margin-top: 27px;
`;

export const RecoverPassword = styled.button`
  background-color: transparent;
  width: 181px;
  height: 40px;
  border: 0;
  text-decoration: underline;
  color: white;
  font-family: 'ProximaNova-Regular';
  margin-top: ${(props) => props.marginTop};
  font-size: 13px;
  &:focus {
    outline: 0;
  }
`;

export const Line = styled.div`
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  width: 266px;
  margin-top: 22px;
`;
