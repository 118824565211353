import axios, { Endpoints } from '../../services/api';
import {
  GET_CHECKLIST_START,
  GET_CHECKLIST_SUCCESS,
  GET_CHECKLIST_ERROR,
} from './types';
import { HTTP_STATUS } from '../../constants';
import { logout } from './auth';
import { getAuthToken } from '../../services/local';

export const getChecklist = () => async (dispatch) => {
  dispatch({ type: GET_CHECKLIST_START });

  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  try {
    const response = await axios.get(Endpoints.CHECKLIST, config);

    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      dispatch({
        type: GET_CHECKLIST_SUCCESS,
        checklist: response.data?.data?.checklist,
        userChecklist: response.data?.data?.user_checklist,
      });
    }
  } catch (error) {
    dispatch({ type: GET_CHECKLIST_ERROR });
    const statusCode = error?.response?.status ?? HTTP_STATUS.ERROR;
    if (
      statusCode === HTTP_STATUS.UNHAUTORIZED ||
      statusCode === HTTP_STATUS.FORBIDDEN
    ) {
      dispatch(logout());
    } else {
      return Promise.reject(error?.response?.data?.message);
    }
  }
};

export const saveChecklist = (checklist) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  };

  try {
    const response = await axios.post(Endpoints.CHECKLIST, checklist, config);

    const statusCode = response.status;

    if (statusCode === HTTP_STATUS.SUCCESS) {
      return Promise.resolve(response?.data?.message);
    }
  } catch (error) {
    const statusCode = error?.response?.status ?? HTTP_STATUS.ERROR;
    if (
      statusCode === HTTP_STATUS.UNHAUTORIZED ||
      statusCode === HTTP_STATUS.FORBIDDEN
    ) {
      dispatch(logout());
    } else {
      return Promise.reject(error?.response?.data?.message);
    }
  }
};
