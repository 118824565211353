import styled, { css } from 'styled-components';
import { ANSWER_STATE } from './Answer.constants';
import { colors } from '../../constants/colors';

export const Container = styled.div`
  height: 70px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  cursor: pointer;
  border-radius: 35px;
  width: 100%;
  overflow: hidden;

  ${(props) =>
    props.state === ANSWER_STATE.DEFAULT &&
    css`
      border: 1px solid ${colors.blueMagenta};
      background-color: #fff;
    `}

  ${(props) =>
    props.state === ANSWER_STATE.SELECTED &&
    css`
      border: 1px solid ${colors.blueMagenta};
      background-color: ${colors.lightBlueMagenta};
    `}

  ${(props) =>
    props.state === ANSWER_STATE.WRONG &&
    css`
      border: 1px solid ${colors.wrongRed};
      background-color: ${colors.wrongRed};
    `}

  ${(props) =>
    props.state === ANSWER_STATE.CORRECT &&
    css`
      border: 1px solid ${colors.correctGreen};
      background-color: ${colors.correctGreen};
    `}
`;

export const Text = styled.span`
  font-family: 'ProximaNova-Regular';
  font-size: 15px;

  ${(props) =>
    props.state === ANSWER_STATE.DEFAULT ||
    props.state === ANSWER_STATE.SELECTED
      ? css`
          color: #000;
        `
      : css`
          color: #fff;
        `}
`;
