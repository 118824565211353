import React from 'react';
import ButtonStyle from './CustomButton.styles';

const CustomButton = (props) => {
  const {
    text,
    backgroundColor,
    small,
    style,
    color,
    onClick,
    marginTop,
    disabled,
  } = props;

  return (
    <ButtonStyle
      {...{ backgroundColor }}
      {...{ color }}
      {...{ marginTop }}
      {...{ onClick }}
      {...{ small }}
      {...{ disabled }}
      {...{ style }}
    >
      {text}
    </ButtonStyle>
  );
};

export default CustomButton;
