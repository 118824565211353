import styled from 'styled-components';

const ButtonStyle = styled.button`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: 0;
  font-family: 'ProximaNova-Bold';
  margin-top: ${(props) => props.marginTop};
  font-size: ${(props) => (props.small ? '11px' : '20px')};
  width: ${(props) => (props.small ? '91px' : '181px')};
  height: ${(props) => (props.small ? '18px' : '40px')};
  border-radius: ${(props) => (props.small ? '9px' : '20px')};
  &:focus {
    outline: 0;
  }
`;

export default ButtonStyle;
