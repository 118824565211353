import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DownloadIcon from '../../assets/images/download.svg';
import SaveIcon from '../../assets/images/save.svg';
import ChecklistAccordion from '../../components/ChecklistAccordion/ChecklistAccordion';
import FooterBar from '../../components/FooterBar/FooterBar';
import TopBar from '../../components/TopBar/TopBar';
import { getChecklist, saveChecklist } from '../../store/actions/checklist';
import {
  ChecklistItem,
  Container,
  Element,
  ElementLabel,
  ElementTitle,
  ElementValue,
  ElementInputText,
  Page,
  ElementSelect,
  SpinnerWrapper,
} from './ChecklistScreen.styles';

const ChecklistScreen = (props) => {
  const { history, routes } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const checklist = useSelector((state) => state.checklist.checklist);
  const userChecklist = useSelector((state) => state.checklist.userChecklist);
  const loading = useSelector((state) => state.checklist.loading);

  const [checklistFormatted, setChecklistFormatted] = useState([]);
  const [formData, setFormData] = useState([]);

  const downloadRef = useRef(null);

  const downloadHandler = useCallback(() => {
    downloadRef.current?.click();
  }, [history, routes]);

  const saveChecklistHandler = useCallback(() => {
    // transform array of objects into object to submit
    const map = new Map([...formData.map((item) => [item.name, item.value])]);
    const checklistBody = Object.fromEntries(map);

    dispatch(saveChecklist(checklistBody))
      .then((message) => {
        alert(message);
      })
      .catch((error) => {
        alert(error);
      });
  }, [history, routes, formData]);

  const topLeftButton = useMemo(
    () => ({
      action: downloadHandler,
      icon: DownloadIcon,
      description: 'download',
    }),
    [downloadHandler],
  );

  const topRightButton = useMemo(
    () => ({
      action: saveChecklistHandler,
      icon: SaveIcon,
      description: 'save',
    }),
    [saveChecklistHandler],
  );

  useEffect(() => {
    dispatch(getChecklist());
  }, [dispatch]);

  useEffect(() => {
    const formatted = [];

    let obj = null;

    checklist?.checklist?.forEach((element) => {
      if (element.type === 'header') {
        obj = {
          header: element,
          children: [],
        };

        formatted.push(obj);
      } else if (formatted.length > 0) {
        formatted[formatted.length - 1].children.push(element);
      }
    });

    formatted.map((item) => {
      item.filled = 0;

      item.children?.forEach((elem) => {
        const filledItem = formData?.find((formItem) =>
          elem.type === 'checkbox-group'
            ? formItem.name.includes(elem.name)
            : elem.name === formItem.name,
        );

        if (filledItem) {
          item.filled += 1;
        }
      });

      return item;
    });

    setChecklistFormatted(formatted);
  }, [checklist, formData]);

  useEffect(() => {
    if (userChecklist?.checklist_values) {
      setFormData(
        Object.keys(userChecklist?.checklist_values).map((key) => ({
          name: key,
          value: userChecklist?.checklist_values[key],
        })),
      );
    }
  }, [userChecklist]);

  const handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setFormData((prevState) => {
      const copy = [...prevState];

      if (target.type === 'checkbox') {
        const index = prevState.indexOf(
          prevState.find((elem) => elem.name === name && elem.value === value),
        );
        if (index !== -1) {
          copy.splice(index, 1);
        }

        if (target.checked) {
          copy.push({
            name,
            value,
          });
        }
      } else {
        const index = prevState.indexOf(
          prevState.find((elem) => elem.name === name),
        );
        if (index !== -1) {
          copy.splice(index, 1);
        }

        if (value) {
          copy.push({
            name,
            value,
          });
        }
      }

      return copy;
    });
  };

  const getDOMElement = (elem, index) => {
    if (elem.type === 'checkbox-group') {
      return (
        <Element key={`Element_checkbox-group_${index}`}>
          <ElementTitle>{elem.label}</ElementTitle>
          {elem.values?.map((checkbox, index) => (
            <ElementValue
              key={`ElementValue_checkbox_${index}_${checkbox.value}`}
            >
              <input
                type="checkbox"
                id={checkbox.value}
                name={`${elem.name}[${index}]`}
                value={checkbox.value}
                checked={
                  !!formData.find(
                    (formElem) =>
                      formElem.name === `${elem.name}[${index}]` &&
                      formElem.value === checkbox.value,
                  )
                }
                onChange={handleInputChange}
              />
              <ElementLabel>{checkbox.label}</ElementLabel>
            </ElementValue>
          ))}
        </Element>
      );
    }
    if (elem.type === 'text') {
      return (
        <Element key={`Element_text_${index}`}>
          <ElementTitle htmlFor={elem.name}>{elem.label}</ElementTitle>
          <ElementInputText
            type={elem.subtype}
            name={elem.name}
            value={
              formData.find((formElem) => formElem.name === elem.name)?.value ??
              ''
            }
            onChange={handleInputChange}
          />
        </Element>
      );
    }
    if (elem.type === 'radio-group') {
      return (
        <Element key={`Element_radio-group_${index}`}>
          <ElementTitle>{elem.label}</ElementTitle>
          {elem.values?.map((radio) => (
            <ElementValue key={`ElementValue_radio_${index}_${radio.value}`}>
              <input
                type="radio"
                id={radio.value}
                name={elem.name}
                value={radio.value}
                checked={
                  !!formData.find(
                    (formElem) =>
                      formElem.name === elem.name &&
                      formElem.value === radio.value,
                  )
                }
                onChange={handleInputChange}
              />

              <ElementLabel>{radio.label}</ElementLabel>
            </ElementValue>
          ))}
        </Element>
      );
    }
    if (elem.type === 'select') {
      return (
        <Element key={`Element_select_${index}`}>
          <ElementTitle htmlFor={elem.name}>{elem.label}</ElementTitle>
          <ElementSelect
            name={elem.name}
            required={elem.required}
            value={
              formData.find((formElem) => formElem.name === elem.name)?.value
            }
            onChange={handleInputChange}
          >
            <option disabled selected value>
              {' '}
            </option>
            {elem.values?.map((option) => (
              <option
                key={`ElementSelect_${index}_${option.value}`}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </ElementSelect>
        </Element>
      );
    }
  };

  return (
    <Page>
      <TopBar
        title={t('checklist')}
        leftButton={topLeftButton}
        rightButton={topRightButton}
      />
      <a
        style={{ visibility: 'hidden' }}
        ref={downloadRef}
        href={`${t('checklist_pdf_download')}`}
        download
      >
        download
      </a>
      <Container>
        {loading ? (
          <SpinnerWrapper>
            <div className="spinner-border" role="status" />
          </SpinnerWrapper>
        ) : (
          checklistFormatted.length > 0 && (
            <ChecklistAccordion items={checklistFormatted}>
              {checklistFormatted.map((item, index) => (
                <ChecklistItem key={`ChecklistItem_${index}`}>
                  {item.children?.map((elem, elemIndex) =>
                    getDOMElement(elem, elemIndex),
                  )}
                </ChecklistItem>
              ))}
            </ChecklistAccordion>
          )
        )}
      </Container>
      <FooterBar routes={routes} />
    </Page>
  );
};

export default withRouter(ChecklistScreen);
