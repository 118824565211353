import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import React, { Children, useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {
  AccordionHeaderTitle,
  AccordionIcon,
  StyledAccordion,
  CardBody,
  CardHeader,
  AccordionHeaderContainer,
  CardContainer,
} from './CustomAccordion.styles';

const DefaultAccordion = (props) => {
  const { titles, children, headerColor, defaultActiveKey, fullscreen } = props;

  const AccordionHeader = ({ eventKey, callback, title, fullscreen }) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <CardHeader onClick={decoratedOnClick} fullscreen={fullscreen}>
        <AccordionHeaderContainer fullscreen={fullscreen}>
          <AccordionHeaderTitle {...{ headerColor }} fullscreen={fullscreen}>
            {title}
          </AccordionHeaderTitle>
          <div className="ml-auto">
            <AccordionIcon
              {...{ headerColor }}
              fullscreen={fullscreen}
              className={`fas fa-angle-${isCurrentEventKey ? 'up' : 'down'}`}
            />
          </div>
        </AccordionHeaderContainer>
      </CardHeader>
    );
  };

  return (
    <StyledAccordion
      defaultActiveKey={defaultActiveKey}
      fullscreen={fullscreen}
    >
      {Children?.map(children, (child, index) => (
        <CardContainer key={index} fullscreen={fullscreen}>
          <AccordionHeader
            eventKey={`${index + 1}`}
            title={titles[index]}
            fullscreen={fullscreen}
          />
          <Accordion.Collapse eventKey={`${index + 1}`}>
            <CardBody fullscreen={fullscreen}>{child}</CardBody>
          </Accordion.Collapse>
        </CardContainer>
      ))}
    </StyledAccordion>
  );
};

export default DefaultAccordion;
