import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Title,
  Subtitle,
  LeftButton,
  RightButton,
  TopBarContainer,
  LogoImage,
} from './TopBar.styles';
import { colors } from '../../constants/colors';
import Logo from '../../assets/images/logo.png';
import BackIcon from '../../assets/images/back.svg';

const TopBar = (props) => {
  const {
    subtitle,
    history,
    hasBackButton,
    backgroundColor,
    title,
    rightButton,
    leftButton,
  } = props;

  return (
    <TopBarContainer {...colors} backgroundColor={backgroundColor}>
      {hasBackButton && (
        <LeftButton type="button" onClick={history.goBack}>
          <img src={BackIcon} alt="back" />
        </LeftButton>
      )}

      {leftButton && (
        <LeftButton type="button" onClick={leftButton.action}>
          <img src={leftButton.icon} alt={leftButton.description} />
        </LeftButton>
      )}

      {subtitle ? (
        <Subtitle>{subtitle}</Subtitle>
      ) : title ? (
        <Title>{title}</Title>
      ) : (
        <LogoImage big={!hasBackButton} alt="logo" src={Logo} />
      )}

      {rightButton && (
        <RightButton type="button" onClick={rightButton.action}>
          <img src={rightButton.icon} alt={rightButton.description} />
        </RightButton>
      )}
    </TopBarContainer>
  );
};

export default withRouter(TopBar);
