import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page, Container } from './HomeScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import ListCard from '../../components/ListCard/ListCard';
import ProfileImg from '../../assets/images/profile_img.png';
import AreaImg from '../../assets/images/area_img.png';
import FooterBar from '../../components/FooterBar/FooterBar';
import { colors } from '../../constants/colors';

const HomeScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        id: 1,
        name: t('limiting_profile'),
        color: colors.darkOcean,
        picture_index: ProfileImg,
      },
      {
        id: 2,
        name: t('area'),
        color: colors.orange,
        picture_index: AreaImg,
      },
    ],
    [t],
  );

  const itemClickHandler = (item) => {
    if (item.id === 1) {
      history.push(routes.PROFILES.path);
    } else if (item.id === 2) {
      history.push(routes.AREAS.path);
    }
  };

  return (
    <Page>
      <TopBar />
      <Container>
        {items.map((item) => (
          <ListCard key={item.id} onClick={itemClickHandler} item={item} />
        ))}
      </Container>
      <FooterBar routes={routes} />
    </Page>
  );
};

export default withRouter(HomeScreen);
