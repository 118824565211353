export const ONBOARDING_ITEMS = '/onboarding-items';
export const PROFILES = '/profiles';
export const AREAS = '/areas';
export const FAQS = '/faqs';
export const RANKING = '/app-users';
export const LOGIN = '/auth/login';
export const SIGNUP = '/auth/register';
export const RECOVER_PASSWORD = '/recoverpassword';
export const CHANGE_PASSWORD = '/changepassword';
export const GET_USER = '/app-users/profile';
export const UPDATE_USER = '/app-users/update';
export const QUIZ_AREA = '/quiz/area/:area_id:';
export const QUIZ_PROFILE = '/quiz/profile/:profile_id:';
export const REGISTER_ANSWER = '/user-answer';
export const CHECKLIST = '/checklist';
