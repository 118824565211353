import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import CustomAccordion from '../../components/CustomAccordion/CustomAccordion';
import { getOnboardingItems } from '../../store/actions/onboarding';

import {
  Page,
  Container,
  LogoImage,
  BottomContainer,
  ItemsWrapper,
  SpinnerWrapper,
} from './WelcomeScreen.styles';
import Logo from '../../assets/images/logo.png';
import LogoTDP from '../../assets/images/logo-tdp.png';
import LogoAcPT from '../../assets/images/logo-accessible-portugal.png';

const WelcomeScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const items = useSelector((state) => state.onboarding.items);
  const loading = useSelector((state) => state.onboarding.loading);

  const titles = useMemo(() => items?.map((item) => item.title), [items]);

  useEffect(() => {
    dispatch(getOnboardingItems());
  }, [dispatch]);

  return (
    <Page>
      <Container>
        <LogoImage className="logo_img" alt="logo" src={Logo} />
        {loading ? (
          <SpinnerWrapper>
            <div className="spinner-border" role="status" />
          </SpinnerWrapper>
        ) : (
          items && (
            <ItemsWrapper>
              <CustomAccordion fullscreen="fullscreen" titles={titles}>
                {items.map((item) => (
                  <span
                    key={item.id}
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  />
                ))}
              </CustomAccordion>
            </ItemsWrapper>
          )
        )}

        <BottomContainer>
          <CustomButton
            small
            backgroundColor="white"
            color={colors.greenCyan}
            text={t('know_more').toLocaleUpperCase()}
            marginTop="30px"
            onClick={() => history.push(routes.KNOW_MORE.path)}
            style={{
              fontSize: '15px',
              width: '130px',
              height: '30px',
              borderRadius: '25px',
            }}
          />

          <CustomButton
            backgroundColor={colors.blueMagenta}
            color="white"
            text={t('continue')}
            marginTop="26px"
            onClick={() => history.push(routes.HOME.path)}
          />
        </BottomContainer>
        <BottomContainer>
          <div>
            <LogoImage
              className="logo_img"
              alt="logo-tdp"
              src={LogoTDP}
              style={{
                filter: 'brightness(0) invert(1)',
                margin: '10px',
                maxWidth: '100px',
              }}
            />
            <LogoImage
              className="logo_img"
              alt="logo-acpt"
              src={LogoAcPT}
              style={{
                filter: 'brightness(0) invert(1)',
                margin: '10px',
                maxWidth: '100px',
              }}
            />
          </div>
        </BottomContainer>
      </Container>
    </Page>
  );
};

export default withRouter(WelcomeScreen);
