import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
`;

export const DetailImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 30vh;
`;

export const Subtitle = styled.span`
  color: #000;
  font-family: 'ProximaNova-Bold';
  font-size: 19px;
  margin-top: 16px;
  margin-left: 25px;
  margin-right: 25px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
