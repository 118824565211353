import { Accordion, Card, useAccordionToggle } from 'react-bootstrap';
import React, { Children, useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {
  AccordionHeaderTitle,
  AccordionIcon,
  StyledAccordion,
  CardBody,
  CardHeader,
  AccordionHeaderContainer,
  AccordionHeaderFilledIcon,
  AccordionHeaderFilledCounter,
  AccordionHeaderRightWrapper,
} from './ChecklistAccordion.styles';
import CheckedBox from '../../assets/images/checked_box.svg';

const ChecklistAccordion = (props) => {
  const { children, items } = props;

  const AccordionHeader = ({ eventKey, callback, title, total, filled }) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <CardHeader onClick={decoratedOnClick}>
        <AccordionHeaderContainer>
          <AccordionHeaderTitle>{title}</AccordionHeaderTitle>

          <AccordionHeaderRightWrapper>
            <AccordionHeaderFilledIcon src={CheckedBox} alt="filled" />
            <AccordionHeaderFilledCounter>{`${
              filled ?? 0
            }/${total}`}</AccordionHeaderFilledCounter>
            <AccordionIcon
              className={`fas fa-angle-${isCurrentEventKey ? 'up' : 'down'}`}
            />
          </AccordionHeaderRightWrapper>
        </AccordionHeaderContainer>
      </CardHeader>
    );
  };

  return (
    <StyledAccordion>
      {Children?.map(children, (child, index) => (
        <Card key={index}>
          <AccordionHeader
            eventKey={`${index + 1}`}
            title={items[index].header.label}
            total={items[index].children.length}
            filled={items[index].filled}
          />
          <Accordion.Collapse eventKey={`${index + 1}`}>
            <CardBody>{child}</CardBody>
          </Accordion.Collapse>
        </Card>
      ))}
    </StyledAccordion>
  );
};

export default ChecklistAccordion;
