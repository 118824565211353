import styled from 'styled-components';

const InputStyle = styled.input`
  border-radius: 20px;
  border: 2px solid ${(props) => props.borderColor};
  height: 35px;
  width: 266px;
  font-size: 13px;
  padding-left: 16px;
  padding-right: 16px;
  outline: none;
  margin-top: ${(props) => props.marginTop};
`;

export default InputStyle;
