import styled, { css } from 'styled-components';
import { Card, Accordion } from 'react-bootstrap';

export const CardContainer = styled(Card)`
  ${(props) =>
    props.fullscreen &&
    css`
      align-items: center;
      background: transparent;
    `};
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;

  ${(props) =>
    props.fullscreen &&
    css`
      align-items: center;
    `};
`;

export const AccordionHeaderTitle = styled.div`
  color: ${(props) => (props.fullscreen ? '#fff' : props.headerColor)};
  font-size: ${(props) => (props.fullscreen ? '29px' : '14px')};
  padding: ${(props) => (props.fullscreen ? '0px 20px 0px 0px' : 'inherit')};
  display: flex;
  align-items: center;
  font-family: ${(props) =>
    props.fullscreen ? 'ProximaNova-Bold' : 'inherit'};
  text-transform: ${(props) => (props.fullscreen ? 'uppercase' : 'inherit')};
  text-align: ${(props) => (props.fullscreen ? 'center' : 'inherit')};
  width: ${(props) => (props.fullscreen ? '100%' : 'inherit')};
  display: ${(props) => (props.fullscreen ? 'block' : 'flex')};
`;

export const AccordionIcon = styled.i`
  color: ${(props) => (props.fullscreen ? '#fff' : props.headerColor)};
`;

export const AccordionBullet = styled.i`
  color: ${(props) => props.headerColor};
  font-size: 5px;
  margin-right: 5px;
`;

export const CardHeader = styled(Card.Header)`
  border: 0;
  background-color: ${(props) => (props.fullscreen ? 'transparent' : '#fff')};
  border-bottom: 0;
  padding-bottom: 10px;
  font-family: 'ProximaNova-SemiBold';
`;

export const CardBody = styled(Card.Body)`
  font-size: 12px;
  padding-top: 0px;
  font-family: 'ProximaNova-Regular';
  color: ${(props) =>
    props.fullscreen ? '#fff !important' : 'rgba(0, 0, 0, 0.5)'};

  * {
    color: ${(props) =>
      props.fullscreen ? '#fff !important' : 'rgba(0, 0, 0, 0.5)'};
  }

  ul,
  li {
    margin-left: 0px !important;
    padding-left: 8px;
    max-width: ${(props) => (props.fullscreen ? '360px' : 'inherit')};
  }
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
  padding: 15px 6px;
  .card {
    border: 0;
  }
`;
