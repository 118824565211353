import { createGlobalStyle } from 'styled-components';
import ProximaNovaRegular from './assets/fonts/ProximaNova-Regular.otf';
import ProximaNovaBold from './assets/fonts/ProximaNova-Bold.otf';
import ProximaNovaSemiBold from './assets/fonts/ProximaNova-Semibold.otf';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { colors } from './constants/colors';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ProximaNova-Regular';
    src: url(${ProximaNovaRegular}) format('truetype');
  }
  @font-face {
    font-family: 'ProximaNova-SemiBold';
    src: url(${ProximaNovaSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'ProximaNova-Bold';
    src: url(${ProximaNovaBold}) format('truetype');
  }
  body {
    font-family: 'ProximaNova-Regular';
    margin: 0;
    overscroll-behavior-y: contain;
    max-width: 1024px;
    margin: 0px auto;
    background-color: ${colors.darkTopbar};

    #root {
      background-color: #fff;
    }

    b {
      font-family: 'ProximaNova-Bold';
    }

    button {
      -webkit-appearance: none;
      -webkit-border-radius: 0;
    }

    .spinner-border {
      color: ${colors.blueMagenta};
    }
  }
`;

export default GlobalStyles;
