export const colors = {
  blueMagenta: '#c882ff',
  lightBlueMagenta: '#E3C0FF',
  darkTopbar: '#212837',
  darkFooterBar: '#101520',
  greenCyan: '#0ddd5c',
  gray: '#aaaaaa',
  darkOcean: '#1E4591',
  orange: '#D8693B',
  green: '#4BCBA4',
  wrongRed: '#CC2B2B',
  correctGreen: '#75C823',
  gold: '#FFD000',
  silver: '#B9B9B9',
  bronze: '#DF882B',
  transparent: 'transparent',
};
