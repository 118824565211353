import styled from 'styled-components';
import { colors } from '../../constants/colors';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const AvatarContainer = styled.div`
  margin-top: 32px;
  position: relative;
`;

export const Avatar = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50px;
`;

export const AvatarEditButton = styled.img`
  height: 17px;
  position: absolute;
  top: -5px;
  right: -5px;
`;

export const UsernameContainer = styled.div`
  margin-top: 23px;
  position: relative;
`;

export const Username = styled.span`
  color: #000;
  font-size: 19px;
  font-family: 'ProximaNova-Regular';
  text-align: center;
  padding: 0;
  margin: 0;
  max-width: 280px;
`;

export const UsernameEditButton = styled.img`
  height: 17px;
  position: absolute;
  top: -5px;
  right: -30px;
`;

export const ScoreContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScoreImg = styled.img`
  height: 34px;
`;

export const ScoreTitle = styled.span`
  margin-top: 9px;
  font-family: 'ProximaNova-Regular';
  font-size: 19px;
  color: ${colors.blueMagenta};
`;

export const Score = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 22px;
  color: #000;
`;

export const RankingContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RankingImg = styled.img`
  height: 60px;
`;

export const RankingTitle = styled.span`
  margin-top: 9px;
  font-family: 'ProximaNova-Regular';
  font-size: 19px;
  color: ${colors.blueMagenta};
`;

export const Ranking = styled.span`
  font-family: 'ProximaNova-Bold';
  font-size: 22px;
  color: #000;
`;

export const PrivacyPolicyLink = styled.a`
  color: #000;
  text-decoration: underline;
  font-family: 'ProximaNova-Bold';
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
