import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Element = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const ElementTitle = styled.label`
  margin-bottom: 0;
  font-size: 15px;
  font-family: 'ProximaNova-Bold';
`;

export const ElementValue = styled.label`
  margin-bottom: 0;
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

export const ElementLabel = styled.span`
  margin-left: 7px;
  font-size: 15px;
`;

export const ElementInputText = styled.input`
  font-size: 15px;
  margin-top: 12px;
`;

export const ElementSelect = styled.select`
  font-size: 15px;
  margin-top: 12px;
`;
