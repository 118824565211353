import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Container,
  SubHeader,
  SubHeaderTitle,
  QuestionsCounter,
  Question,
  AnswersContainer,
  AnswerWrapper,
} from './QuizScreen.styles';
import TopBar from '../../components/TopBar/TopBar';
import { getQuiz, registerAnswer } from '../../store/actions/quiz';
import Answer from '../../components/Answer/Answer';
import { ANSWER_STATE } from '../../components/Answer/Answer.constants';
import CustomButton from '../../components/CustomButton/CustomButton';
import { colors } from '../../constants/colors';
import {
  clearCurrentQuestion,
  getCurrentQuestion,
  setCurrentQuestion,
} from '../../services/local';

const QuizScreen = (props) => {
  const { history, routes } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quiz = useSelector((state) => state.quiz.quiz);
  const results = useSelector((state) => state.quiz.quizResults);

  const [question, setQuestion] = useState(null);
  const [itemInfo, setItemInfo] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [answered, setAnswered] = useState(false);

  const getAnswerState = useCallback(
    (answer) => {
      if (!answered && !selectedAnswer) {
        return ANSWER_STATE.DEFAULT;
      }

      if (selectedAnswer && selectedAnswer.id === answer.id && !answered) {
        return ANSWER_STATE.SELECTED;
      }

      if (selectedAnswer && answered) {
        return answer.is_correct ? ANSWER_STATE.CORRECT : ANSWER_STATE.WRONG;
      }

      return ANSWER_STATE.DEFAULT;
    },
    [answered, selectedAnswer],
  );

  const showResults = useCallback(() => {
    const historyState = history.location.state;

    history.replace({
      pathname: routes.QUIZ_RESULTS.path
        .replace(':type', historyState.type)
        .replace(':id', itemInfo.id),
      state: historyState,
    });
  }, [itemInfo, history, routes]);

  useEffect(() => {
    if (results) {
      showResults();
    }
  }, [results, showResults]);

  useEffect(() => {
    const historyState = history.location.state;

    setItemInfo(historyState?.itemInfo);

    if (!results && !quiz) {
      dispatch(
        getQuiz({
          profileId: historyState.profileId,
          areaId: historyState.areaId,
        }),
      );
    }
  }, [dispatch, results, quiz, history]);

  const nextQuestion = useCallback(() => {
    if (quiz?.questions) {
      if (question) {
        if (question.order === quiz.questions.length) {
          showResults();
        } else if (question.order < quiz.questions.length) {
          setSelectedAnswer(null);
          setAnswered(false);
          setQuestion(quiz.questions[question.order]);
          setCurrentQuestion(question.order);
        }
      } else {
        setQuestion(quiz.questions[0]);
      }
    }
  }, [quiz, question, showResults]);

  useEffect(() => {
    if (quiz) {
      const localCurrentQuestion = getCurrentQuestion();

      if (localCurrentQuestion) {
        setQuestion(quiz.questions[localCurrentQuestion]);
      } else {
        nextQuestion();
      }
    }
  }, [quiz]);

  useEffect(
    () => () => {
      clearCurrentQuestion();
    },
    [],
  );

  const registerAnswerHandler = useCallback(() => {
    setAnswered(true);

    dispatch(
      registerAnswer(
        question.id,
        selectedAnswer.id,
        question.order === quiz.questions.length,
      ),
    ).catch((error) => {
      alert(error);
    });
  }, [dispatch, question, selectedAnswer, question, quiz]);

  return (
    <Page>
      {quiz && !results && itemInfo && (
        <>
          <TopBar hasBackButton title={t('quiz')} />
          <SubHeader backgroundColor={itemInfo.color}>
            <SubHeaderTitle>{itemInfo.name}</SubHeaderTitle>
          </SubHeader>
          <Container>
            {question && (
              <>
                <QuestionsCounter>
                  {`${question.order} / ${quiz.questions.length}`}
                </QuestionsCounter>
                <Question>{question.question}</Question>
                <AnswersContainer>
                  {question.answers?.map((answer) => (
                    <AnswerWrapper>
                      <Answer
                        answer={answer}
                        state={getAnswerState(answer)}
                        onSelect={setSelectedAnswer}
                      />
                    </AnswerWrapper>
                  ))}
                </AnswersContainer>
                {selectedAnswer && !answered && (
                  <CustomButton
                    backgroundColor={colors.blueMagenta}
                    marginTop="28px"
                    color="white"
                    text={t('answer').toLocaleUpperCase()}
                    onClick={registerAnswerHandler}
                  />
                )}
                {selectedAnswer && answered && (
                  <CustomButton
                    backgroundColor={colors.blueMagenta}
                    marginTop="28px"
                    color="white"
                    text={t('next').toLocaleUpperCase()}
                    onClick={nextQuestion}
                  />
                )}
              </>
            )}
          </Container>
        </>
      )}
    </Page>
  );
};

export default withRouter(QuizScreen);
